import React from 'react';
import styled from 'styled-components';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../../components/layout/ContentWrapper';

import ContactForm from '../../../components/forms/ContactForm';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';
import Text from '@rotaready/frecl/build/Text';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import Button from '@rotaready/frecl/build/Button';

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 1 50%;
    margin: 0;
  }
`;

const Section = styled.div`
  margin: 0 0 40px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding-right: 120px;
  }
`;

const Heading = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const TextBlock = styled.div`
  margin-top: 20px;
`;

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

class RbtlLandingPage extends React.Component {
  render() {
    return (
      <Body header={header}>
        <SEO
          title="RBTL 2022 - Rotaready"
          description="Meet with us at Restaurant & Bar Tech Live 2022"
          url="landing/rbtl-2022"
        />

        <ResponsiveContainer>
          <ContentWrapper>
            <TitleWrapper>
              <H1 uistyle="brand160" text="Meet with us at Restaurant & Bar Tech Live" />
            </TitleWrapper>

            <BodyWrapper>
              <BodyItem>
                <Section>
                  <Heading text="Meet with us" />

                  <TextBlock>
                    <Text size="lg" text="If you'd like to meet with us at a specific time during Restaurant & Bar Tech Live, please complete the form on this page and we'll be in touch to confirm. Looking forward to seeing you there!" />
                  </TextBlock>
                </Section>

                <Section>
                  <Heading text="Event details" />

                  <TextBlock>
                    <UL size="lg" uistyle="normal" iconUistyle="primary">
                      <TextListItem text="Restaurant & Bar Tech Live" />
                      <TextListItem text="19-20th October 2022" />
                      <TextListItem text="ExCel London, Royal Victoria Dock, 1 Western Gateway, Royal Docks, London, E16 1XL" />
                    </UL>
                  </TextBlock>

                  <TextBlock>
                    <a href="http://www.restauranttechlive.co.uk/visit/" target="_blank" rel="noopener noreferrer">
                      <Button uistyle="primary" text="Official show website" ghost borderless size="lg" />
                    </a>
                  </TextBlock>
                </Section>
              </BodyItem>

              <BodyItem>
                <ContactForm
                  title="Book a meeting"
                  commentsLabel="Please let us know the time and date you would like to meet and we'll do our very best to accommodate"
                  submitLabel="Send details"
                  showComments
                  showPhone
                  source="rbtl_2022"
                  label="rotaready.com/landing/rbtl-2022"
                />
              </BodyItem>
            </BodyWrapper>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default RbtlLandingPage;
